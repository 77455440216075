import { gql } from '@apollo/client'

export const GET_SUMMARY = gql`
  query GetSummary(
    $start: timestamptz!
    $end: timestamptz!
    $departmentId: String!
  ) {
    department_categories_schedules(
      where: {
        department_id: { _eq: $departmentId }
        _or: [
          { department_category: { deleted_at: { _gt: $start } } }
          { department_category: { deleted_at: { _is_null: true } } }
        ]
      }
      order_by: { department_category: { name: asc, slots: asc, id: asc } }
    ) {
      department_category {
        id
        name
        slots
      }
      department {
        name
      }
      schedule {
        id
        start_hour
        week_day
        checkins(where: { checkin_event_at: { _gte: $start, _lte: $end } }) {
          id
          status
          checkin_event_at
          department_category_id
          contact {
            id
            name
            phone
          }
        }
      }
    }
  }
`
