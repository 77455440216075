import { gql } from '@apollo/client'

export const GET_VOLUNTEER_SUGGESTIONS = gql`
  query GetVolunteerSuggestions($departmentId: String!) {
    contacts(order_by: { name: asc_nulls_last }) {
      id
      name
      phone
      birth_date
      departments_contacts_aggregate(
        distinct_on: department_category_id
        where: { department_id: { _eq: $departmentId } }
      ) {
        nodes {
          department_category_id
        }
      }
    }
  }
`
