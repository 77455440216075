import { useQuery } from '@apollo/client'
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { GET_DEPARTMENT_DETAILS } from './GraphQL/GetDepartmentDetailsQuery'
import { GetDepartmentDetailsQuery } from '../_GeneratedGql/graphql'
import Select from 'react-select'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'
import { DepartmentDetailsForm } from './DepartmentDetailsForm'
import { isAdmin } from '../Utils/Auth'
import { ulid } from 'ulid'

type Department = GetDepartmentDetailsQuery['departments'][0]

type DepartmentPageInput = {}

export function DepartmentPage(_props: DepartmentPageInput) {
  const { loading, error, data } = useQuery<GetDepartmentDetailsQuery>(
    GET_DEPARTMENT_DETAILS,
    { fetchPolicy: 'network-only' }
  )

  const [department, setActiveDepartment] = useState<Department>()

  useEffect(() => {
    if (data) {
      setActiveDepartment(data.departments[0])
    }
  }, [data])

  const emptyDepartment = (): Department => {
    const id = ulid()
    return {
      id,
      color: '#1273de',
      name: '',
      timezone_offset: -3,
      schedules: [
        { id: ulid(), department_id: id } as Department['schedules'][0],
      ],
      department_categories: [],
      contact: {
        id: ulid(),
        user_id: null,
        name: '',
        phone: '',
      },
    }
  }

  if (loading) return <AbsoluteCircularProgress />
  if (error) return <Box className="text-center">Erro ao carregar eventos</Box>

  return (
    <Box className="container">
      <Typography variant="h5" fontWeight="bold" color="primary">
        Gerenciar ministério
      </Typography>

      <Box className="d-flex">
        {data!.departments.length > 1 && (
          <Select
            defaultValue={data!.departments[0]}
            onChange={(department) =>
              setActiveDepartment(department as Department)
            }
            value={department}
            options={data!.departments}
            getOptionLabel={(opt: Department) => opt.name}
            getOptionValue={(opt: Department) => opt.name}
            className="mt-2 w-100"
            isSearchable
          />
        )}

        {isAdmin() && (
          <Button
            onClick={() => setActiveDepartment(emptyDepartment())}
            className="mt-2 ml-2"
            variant="contained"
          >
            Cadastrar
          </Button>
        )}
      </Box>

      <hr />

      {department && (
        <DepartmentDetailsForm
          reset={() => {
            setActiveDepartment(undefined)
            setTimeout(() => setActiveDepartment(department))
          }}
          department={department}
        />
      )}
    </Box>
  )
}
