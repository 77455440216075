import { useState, useEffect } from 'react'
import Alert, { AlertProps } from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

type AlertWithTimeoutInput = AlertProps & {
  timeout: number
  children: React.ReactNode
  closable?: boolean
  closeCallback?: () => void
}

export function AlertWithTimeout({
  timeout,
  closable,
  closeCallback,
  ...input
}: AlertWithTimeoutInput) {
  const [open, setOpen] = useState(true)
  const [timer, setTimer] = useState<any>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false)
      closeCallback && closeCallback()
    }, timeout)

    setTimer(timer)

    return () => {
      clearTimeout(timer)
    }
  }, [timeout, closeCallback])

  let alertInput = { ...input }

  if (closable) {
    alertInput.onClose = () => {
      setOpen(false)
      clearTimeout(timer)
      closeCallback && closeCallback()
    }
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {open && <Alert {...alertInput}>{input.children}</Alert>}
    </Stack>
  )
}
