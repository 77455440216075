import { CalendarSummary } from './Calendar/CalendarSummary'
import { DepartmentPage } from './Department/DepartmentPage'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { VolunteersPage } from './Volunters/VolunteersPage'
import LoginPage from './Login/LoginPage'
import PrivateRoute from './Utils/PrivateRoute'
import { MonthlySummary } from './MonthlySummary/MonthlySummary'

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute path="calendar" element={<CalendarSummary />} />,
  },
  {
    path: '/departments',
    element: <PrivateRoute path="departments" element={<DepartmentPage />} />,
  },
  {
    path: '/volunteers',
    element: <PrivateRoute path="volunteers" element={<VolunteersPage />} />,
  },
  {
    path: '/summary',
    element: <PrivateRoute path="summary" element={<MonthlySummary />} />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
])

function App() {
  return <RouterProvider router={router} />
}

export default App
