import jwtDecode from 'jwt-decode'

type Claims = {
  'x-hasura-allowed-roles'?: Array<string>
  'x-hasura-default-role'?: string
  'X-Hasura-User-Id'?: string
  'X-Hasura-Active-Organization-Id'?: string
}

const getClaims = (): Claims => {
  const token = localStorage.getItem('token') as string

  if (!token) return {}
  const decoded = jwtDecode(token) as any
  return decoded['https://hasura.io/jwt/claims'] || {}
}

export const isAdmin = (): boolean =>
  (jwtDecode(localStorage.getItem('token') || '') as any)?.admin || false

export const isAdminMaster = (): boolean => {
  const claims = getClaims()
  const roles = claims['x-hasura-allowed-roles']

  if (!roles) return false

  return roles.indexOf('admin') > -1
}

export const getUserId = (): string | null => {
  const claims = getClaims()

  return claims['X-Hasura-User-Id'] || null
}

export const getOrgId = (): string => {
  const claims = getClaims()

  return claims['X-Hasura-Active-Organization-Id']!
}
