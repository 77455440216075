import { useQuery } from '@apollo/client'
import { Alert, Box, IconButton, Typography } from '@mui/material'
import { CalendarEvent } from 'kalend'
import {
  GetEventCheckinsQuery,
  UpsertCheckinInput,
} from '../_GeneratedGql/graphql'
import Select from 'react-select'
import { GET_EVENT_CHECKINS } from './GraphQL/GetEventCheckinsQuery'
import { useState, useEffect } from 'react'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'
import { BlockRounded } from '@mui/icons-material'
import { ulid } from 'ulid'
import { isAdminMaster } from '../Utils/Auth'

type Contact =
  GetEventCheckinsQuery['department_categories'][0]['departments_contacts'][0]['contact']

type Category = GetEventCheckinsQuery['department_categories'][0]

type CategoryCheckin =
  GetEventCheckinsQuery['department_categories'][0]['checkins'][0]

export interface CalendarEventDetailsInput {
  selectedEvent: CalendarEvent | null
  setCheckins: React.Dispatch<React.SetStateAction<UpsertCheckinInput[]>>
  setOriginalCheckins: React.Dispatch<
    React.SetStateAction<UpsertCheckinInput[]>
  >
}

export function CalendarEventDetails({
  selectedEvent,
  setCheckins,
  setOriginalCheckins,
}: CalendarEventDetailsInput) {
  const startAt = new Date(selectedEvent!.startAt).toISOString()

  const variables = {
    scheduleId: selectedEvent?.scheduleId,
    eventAt: startAt,
  }
  const { loading, error, data } = useQuery<GetEventCheckinsQuery>(
    GET_EVENT_CHECKINS,
    { variables, fetchPolicy: 'network-only' }
  )

  const [checkinsForCategory, setCheckinsForCategory] = useState(
    new Map<string, CategoryCheckin[]>()
  )

  const buildEmptyCheckin = (cat: Category, checkin?: CategoryCheckin) => {
    return {
      status: checkin?.status || 'none',
      checkin_event_at: checkin?.checkin_event_at || startAt,
      department_category_id: checkin?.department_category_id || cat.id,
      department_id: checkin?.department_id || cat.department_id!,
      schedule_id:
        checkin?.schedule_id || (selectedEvent!.scheduleId as number),
      contact: checkin?.contact || null,
      id: checkin?.id || ulid(),
    } as CategoryCheckin
  }

  const doChangeCheckin = (
    checkin: CategoryCheckin,
    newCheckin: CategoryCheckin
  ) => {
    const clone = new Map(checkinsForCategory)

    clone.forEach((val, key) => {
      const index = val.findIndex((obj: any) => obj === checkin)
      if (index !== -1) {
        val[index] = { ...val[index], ...newCheckin }
        clone.set(key, val)
      }
    })

    setCheckinsForCategory(clone)
  }

  const changeCheckinContact = (contact: Contact, checkin: CategoryCheckin) => {
    const newCheckin = { ...checkin, contact, status: 'none' }

    doChangeCheckin(checkin, newCheckin)
  }

  const changeCheckinBlocked = (checkin: CategoryCheckin) => {
    const newCheckin = {
      ...checkin,
      contact: null,
      status: checkin.status === 'blocked' ? 'none' : 'blocked',
    }

    doChangeCheckin(checkin, newCheckin)
  }

  const getBorderColor = (status: string) => {
    if (status === 'blocked') return 'var(--secondary)'
    if (status === 'accepted') return 'var(--success)'
    if (status === 'invited') return 'var(--warning)'
    return 'inherit'
  }

  const formatCheckinsOutput = (
    checkinsMap: Map<string, CategoryCheckin[]>
  ) => {
    return Array.from(checkinsMap.values())
      .flat()
      .map((c) => ({
        ...c,
        contact: undefined,
        contact_id: c.contact?.id,
      })) as UpsertCheckinInput[]
  }

  useEffect(() => {
    const checkinMap = new Map()

    if (data) {
      data!.department_categories.forEach((cat: Category) => {
        const checkinsNum = Math.max(cat.checkins.length, cat.slots || 0)
        const checkins = Array(checkinsNum)
          .fill(null)
          .map((_, index) => buildEmptyCheckin(cat, cat.checkins[index]))

        checkinMap.set(cat.id, checkins)
      })
    }

    setCheckinsForCategory(checkinMap)
    setOriginalCheckins(formatCheckinsOutput(checkinMap))
  }, [data])

  useEffect(() => {
    const checkins = formatCheckinsOutput(checkinsForCategory)

    setCheckins(checkins)
  }, [checkinsForCategory])

  if (loading) return <AbsoluteCircularProgress />
  if (error) return <div className="p-2">Erro ao carregar evento</div>

  return (
    <Box>
      {data!.department_categories.length === 0 && (
        <Alert severity="info">Não há nada configurado nesse dia</Alert>
      )}
      {data!.department_categories.map((cat) => {
        const key = `department_category-${cat.id}`

        return (
          <Box key={key} className="d-flex flex-column mt-3">
            <Typography className="ml-3 font-weight-bold">
              {cat.name}
            </Typography>
            {(checkinsForCategory.get(cat.id) || []).map((checkin, index) => {
              let optionalInputs = {} as any
              if (checkin.contact?.phone) {
                optionalInputs.defaultValue = checkin!.contact
              }
              const key = `${cat.id}-${checkin.checkin_event_at}-${checkin?.contact?.phone}-${index}`

              return (
                <Box key={key}>
                  <Box
                    className="my-2 pl-2 d-flex align-items-center"
                    sx={{
                      borderLeft: 10,
                      borderColor: getBorderColor(checkin.status),
                    }}
                  >
                    <Select
                      {...optionalInputs}
                      className="w-100"
                      inputId={key}
                      isDisabled={
                        index + 1 > cat.slots! ||
                        checkin.status === 'blocked' ||
                        loading
                      }
                      key={key}
                      onChange={(opt: Contact) =>
                        changeCheckinContact(opt, checkin)
                      }
                      options={cat.departments_contacts.map((c) => c.contact)}
                      getOptionLabel={(opt: Contact) => opt!.name}
                      getOptionValue={(opt: Contact) => opt!.phone}
                      placeholder={
                        checkin.status === 'blocked'
                          ? 'Bloqueado'
                          : 'Selecione o voluntário'
                      }
                      isSearchable
                      isClearable
                      menuPosition="fixed"
                    />

                    <IconButton
                      color={checkin.status === 'blocked' ? 'default' : 'error'}
                      className="ml-2 p-1"
                      onClick={() => changeCheckinBlocked(checkin)}
                      sx={{
                        outline: '0 !important',
                        borderRadius: 2,
                        border: '1px solid hsl(0, 0%, 80%)',
                      }}
                    >
                      <BlockRounded scale={40}></BlockRounded>
                    </IconButton>
                  </Box>

                  {isAdminMaster() && (
                    <Typography
                      component="p"
                      color={(theme) => theme.palette.grey[500]}
                      fontSize={'10px'}
                    >
                      {checkin.id}
                    </Typography>
                  )}
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}
