import { gql } from '@apollo/client'

export const REMOVE_VOLUNTEER_MUTATION = gql`
  mutation RemoveVolunteer($id: String!, $departmentId: String!) {
    delete_departments_contacts(
      where: { department_id: { _eq: $departmentId }, contact_id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`
