import { gql } from '@apollo/client'

export const GET_ADMIN_CONTACTS = gql`
  query GetAdminContacts {
    contacts(where: { user_id: { _is_null: false } }) {
      id
      name
      phone
      user_id
    }
  }
`
