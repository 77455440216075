import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')

  let result = {
    headers: {
      ...headers,
    },
  }

  if (token) {
    result.headers.authorization = 'Bearer ' + token
  }

  // return the headers to the context so httpLink can read them
  return result
})

const UNAUTHENTICATE_CODES = ['invalid-jwt', 'invalid-headers']

const errorLink = onError(({ graphQLErrors, operation }) => {
  const { headers } = operation.getContext()

  if (
    operation.operationName.toLowerCase() !== 'login' &&
    !headers.authorization
  ) {
    console.log('Token not in header')
    localStorage.removeItem('token')
    window.location.href = '/'
    return
  }

  if (graphQLErrors) {
    const foundError = graphQLErrors.find(
      ({ extensions }) =>
        UNAUTHENTICATE_CODES.indexOf(extensions.code as any) > -1
    )

    if (foundError) {
      console.log('Found auth error')
      localStorage.removeItem('token')
      window.location.href = '/'
    }
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
})
