import { useMutation, useLazyQuery } from '@apollo/client'
import { AddCircle, Close, RemoveCircle } from '@mui/icons-material'
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  IconButton,
  Checkbox,
  FormGroup,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Department_Categories_Insert_Input,
  GetAdminContactsQuery,
  GetDepartmentDetailsQuery,
  Schedules_Insert_Input,
  UpdateDepartmentCategorySchedulesMutation,
  UpdateDepartmentCategorySchedulesMutationVariables,
  UpdateDepartmentMutation,
  UpdateDepartmentMutationVariables,
  UpdateUserDepartmentsMutation,
  UpdateUserDepartmentsMutationVariables,
} from '../_GeneratedGql/graphql'
import { GithubPicker } from 'react-color'
import { UPDATE_DEPARTMENT_MUTATION } from './GraphQL/UpdateDepartmentMutation'
import { UPDATE_DEPARTMENT_USER_MUTATION } from './GraphQL/UpdateDepartmentUserMutation'
import { ulid } from 'ulid'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'
import {
  DaysMap,
  getMinutesDiff,
  getOffset,
  toTimezoneHour,
} from '../Utils/Days'
import { getOrgId, isAdmin } from '../Utils/Auth'
import { AlertWithTimeout } from '../Utils/AlertWithTimeout'
import { GET_ADMIN_CONTACTS } from '../Contact/GraphQL/GetAdminContacts'
import Select from 'react-select'
import { UPDATE_DEPARTMENT_CATEGORY_SCHEDULES_MUTATION } from './GraphQL/UpdateDepartmentCategorySchedulesMutation'

type EmptySchedule = {
  duration: number | null
  start_hour: string | null
  week_day: string | null
  department_id: string
  deleted_at?: string | null
  id: string
}
type EmptyDepartmentCategory = {
  name: string | null
  slots?: number | null
  department_id: string
  deleted_at?: string | null
  department_categories_schedules: { schedule_id: string }[]
  id: string
}
type Department = GetDepartmentDetailsQuery['departments'][0]

const COLORS = [
  '#b80000',
  '#db3e00',
  '#fccb00',
  '#008b02',
  '#006b76',
  '#1273de',
  '#004dcf',
  '#5300eb',
  '#795548',
  '#8bc34a',
  '#9c27b0',
  '#e64a19',
  '#ff9800',
  '#009688',
].map((c) => c.toLowerCase())

type DepartmentDetailsFormInput = {
  department: Department
  reset: Function
}

export function DepartmentDetailsForm({
  department,
  reset,
}: DepartmentDetailsFormInput) {
  const { register, handleSubmit, getValues, control, watch, setValue } =
    useForm()

  watch()

  const [schedules, setSchedules] = useState<EmptySchedule[]>([])
  const [categories, setCategories] = useState<EmptyDepartmentCategory[]>([])
  const [
    updateDepartmentMutation,
    {
      data: updateDepartmentData,
      error: updateDepartmentError,
      loading: updateDepartmentLoading,
      reset: resetDepartment,
    },
  ] = useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(
    UPDATE_DEPARTMENT_MUTATION
  )

  const [
    updateCategoriesMutation,
    {
      data: updateCategoriesData,
      error: updateCategoriesError,
      loading: updateCategoriesLoading,
      reset: resetCategories,
    },
  ] = useMutation<
    UpdateDepartmentCategorySchedulesMutation,
    UpdateDepartmentCategorySchedulesMutationVariables
  >(UPDATE_DEPARTMENT_CATEGORY_SCHEDULES_MUTATION)

  const [
    updateUserDepartmentsMutation,
    {
      data: updateUserDepartmentData,
      error: updateUserDepartmentDataError,
      loading: updateUserDepartmentDataLoading,
      reset: resetUserDepartment,
    },
  ] = useMutation<
    UpdateUserDepartmentsMutation,
    UpdateUserDepartmentsMutationVariables
  >(UPDATE_DEPARTMENT_USER_MUTATION)

  const [getContacts, { data: contacts }] =
    useLazyQuery<GetAdminContactsQuery>(GET_ADMIN_CONTACTS)

  useEffect(() => {
    setSchedules(department.schedules)
    setCategories(department.department_categories)
    resetDepartment()
    resetCategories()
    resetUserDepartment()
  }, [department, resetCategories, resetDepartment, resetUserDepartment])

  useEffect(() => {
    if (isAdmin()) {
      getContacts()
    }
  }, [getContacts])

  const onSubmit = async (data: any) => {
    const depId = department!.id

    const depCats = categories.map((d) => {
      return {
        id: d.id,
        department_id: depId,
        slots: data[`department_category-${d.id}-slots`] || d.slots,
        name: data[`department_category-${d.id}-name`] || d.name,
        deleted_at: d.deleted_at,
      } as Department_Categories_Insert_Input
    })

    const newSchedules = schedules.map((s) => {
      const startHour = data[`schedule-${s.id}-hour`] || s.start_hour
      const endHour = data[`schedule-${s.id}-end_hour`]
      const duration = !!endHour
        ? getMinutesDiff(startHour, endHour)
        : s.duration

      return {
        id: s.id,
        department_id: depId,
        duration: duration,
        start_hour: toTimezoneHour(startHour, department.timezone_offset),
        week_day: data[`schedule-${s.id}-weekday`] || s.week_day,
        deleted_at: s.deleted_at,
      } as Schedules_Insert_Input
    })

    const { data: result, errors } = await updateDepartmentMutation({
      variables: {
        organizationId: getOrgId(),
        id: depId,
        departmentColor: data[`department-${depId}-color`] || department!.color,
        departmentName: data[`department-${depId}-name`] || department!.name,
        depCats,
        schedules: newSchedules,
      },
    })

    if (isAdmin()) {
      const userId =
        data[`department-${depId}-contact`]?.user_id ||
        department.contact?.user_id!
      const contactId =
        data[`department-${depId}-contact`]?.id || department.contact?.id!

      await updateUserDepartmentsMutation({
        variables: { departmentId: depId, userId, contactId },
      })
    }

    if (errors) return

    const persistedCategories = (
      result?.insert_department_categories?.returning || []
    ).map((d) => d.id)

    const objects = Object.entries(getValues())
      .filter(([key, enabled]) => {
        const depId = key.split('-')[1]
        return (
          key.indexOf('department_category_schedule') > -1 &&
          enabled &&
          persistedCategories.indexOf(depId) > -1
        )
      })
      .map(([key]) => {
        return {
          department_id: depId,
          department_category_id: key.split('-')[1],
          schedule_id: key.split('-')[2],
        }
      }) as UpdateDepartmentCategorySchedulesMutationVariables['objects']

    await updateCategoriesMutation({
      variables: {
        departmentId: depId,
        objects,
      },
    })
  }

  const removeSchedule = (scheduleId: string) => {
    setSchedules((schedules) =>
      schedules.map((schedule) => {
        if (schedule.id === scheduleId) {
          schedule.deleted_at = new Date().toISOString()
        }

        return schedule
      })
    )
  }

  const addNewEmptySchedule = (department: Department) => {
    setSchedules((schedules) => {
      const newSchedule = {
        duration: null,
        start_hour: null,
        week_day: null,
        department_id: department.id,
        id: ulid(),
      } as EmptySchedule

      return [...(schedules || []), newSchedule]
    })
  }

  const removeCategory = (departmentCategoryId: string) => {
    setCategories((departmentCategories) => {
      const isPersistedDepartment = department.department_categories.find(
        (d) => d.id === departmentCategoryId
      )

      if (isPersistedDepartment) {
        return departmentCategories.map((schedule) => {
          if (schedule.id === departmentCategoryId) {
            return { ...schedule, deleted_at: new Date().toISOString() }
          }

          return schedule
        })
      }

      return departmentCategories.filter((s) => s.id !== departmentCategoryId)
    })

    setValue('unknown', 'unknown', { shouldDirty: true })
  }

  const addNewEmptyCategory = (department: Department) => {
    setCategories((departmentCategories) => {
      const newDepartmentCategory = {
        name: null,
        slots: 1,
        department_id: department.id,
        department_categories_schedules: schedules.map((s) => ({
          schedule_id: s.id,
        })),
        deleted_at: null,
        id: ulid(),
      } as EmptyDepartmentCategory

      return [...(departmentCategories || []), newDepartmentCategory]
    })
  }

  const formatSchedule = (schedule: EmptySchedule) => {
    const weekday =
      getValues(`schedule-${schedule.id}-weekday`) || schedule.week_day
    const startHour =
      getValues(`schedule-${schedule.id}-hour`) ||
      getLocalHoursBySchedule(schedule).startHourLocal ||
      'N/A'

    return `${DaysMap[weekday] || 'N/A'} - ${startHour.substring(0, 5)}`
  }

  const getLocalHoursBySchedule = (schedule: EmptySchedule) => {
    const date = new Date().toISOString().split('T')[0]
    const ref = new Date(
      `${date}T${schedule.start_hour}${getOffset(department.timezone_offset)}`
    )
    const startHourLocal =
      schedule.start_hour &&
      ref.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
    const endHourLocal =
      schedule.duration &&
      new Date(
        ref.setUTCMinutes(ref.getUTCMinutes() + schedule.duration)
      ).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })

    return { startHourLocal, endHourLocal }
  }

  const resetAll = () => {
    resetDepartment()
    resetCategories()
  }

  return (
    <Box>
      {(updateDepartmentLoading ||
        updateCategoriesLoading ||
        updateUserDepartmentDataLoading) && <AbsoluteCircularProgress />}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-2"
        key={`department-${department.id}`}
      >
        <Box className="row">
          <Box className="col d-flex">
            <TextField
              label="Nome do ministério"
              variant="standard"
              defaultValue={department.name}
              {...register(`department-${department.id}-name`, {
                required: true,
              })}
            ></TextField>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '::before': {
                  width: '25px',
                  height: '25px',
                  background:
                    getValues(`department-${department.id}-color`) ||
                    department.color,
                  content: "''",
                  display: 'block',
                  marginRight: 1,
                  marginLeft: 2,
                },
              }}
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <GithubPicker
                    className="ml-2 m-auto"
                    triangle="hide"
                    colors={COLORS}
                    onChange={({ hex }) => field.onChange(hex)}
                    color={department.color}
                  />
                )}
                name={`department-${department.id}-color`}
              />
            </Box>
          </Box>

          {isAdmin() && contacts && (
            <Box className="col">
              <Typography
                variant="inherit"
                sx={{ fontSize: '.9rem', color: 'rgba(0, 0, 0, 0.6)' }}
              >
                Administrador do ministério
              </Typography>
              <Select
                onChange={(contact) => {
                  setValue(`department-${department.id}-contact`, contact)
                }}
                defaultValue={department.contact}
                options={contacts.contacts}
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => opt.user_id!}
                className="mt-2 w-100"
                placeholder="Selecione o administrador"
                isSearchable
              />
            </Box>
          )}
        </Box>

        <Box className="row mt-3 mb-2">
          <Typography
            className="col"
            variant="h6"
            fontWeight="bold"
            color="primary"
          >
            Horários
          </Typography>
        </Box>

        <Box className="border rounded p-2 d-flex flex-column justify-content-center">
          {(schedules || [])
            .filter((d) => !d.deleted_at)
            .map((schedule, index) => {
              const { startHourLocal, endHourLocal } =
                getLocalHoursBySchedule(schedule)

              return (
                <Box
                  className="d-flex align-items-center my-1"
                  key={`department-${department.id}-schedule-${schedule.id}`}
                >
                  <RadioGroup row defaultValue={schedule.week_day}>
                    {Object.entries(DaysMap).map(([value, label]) => (
                      <FormControlLabel
                        className="ml-0 mb-0"
                        key={`${schedule.id}-${value}`}
                        value={value}
                        label={label}
                        labelPlacement="top"
                        control={
                          <Radio
                            {...register(`schedule-${schedule.id}-weekday`)}
                          />
                        }
                      />
                    ))}
                  </RadioGroup>

                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="time"
                    label="Inicio"
                    variant="standard"
                    className="ml-2"
                    defaultValue={startHourLocal}
                    {...register(`schedule-${schedule.id}-hour`, {
                      required: true,
                    })}
                  ></TextField>

                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="time"
                    label="Fim"
                    variant="standard"
                    className="ml-2"
                    defaultValue={endHourLocal}
                    {...register(`schedule-${schedule.id}-end_hour`, {
                      validate: {
                        biggerTime: (value) =>
                          getValues(`schedule-${schedule.id}-hour`) < value,
                      },
                      required: true,
                    })}
                  ></TextField>

                  {schedules.filter((d) => !d.deleted_at).length > 1 && (
                    <IconButton
                      color="default"
                      className="mt-3"
                      sx={{ outline: '0 !important' }}
                      onClick={() => removeSchedule(schedule.id)}
                    >
                      <RemoveCircle scale={40}></RemoveCircle>
                    </IconButton>
                  )}

                  {index ===
                    schedules.filter((d) => !d.deleted_at).length - 1 && (
                    <IconButton
                      color="primary"
                      className="mt-3"
                      sx={{ outline: '0 !important' }}
                      onClick={() => addNewEmptySchedule(department)}
                    >
                      <AddCircle scale={40}></AddCircle>
                    </IconButton>
                  )}
                </Box>
              )
            })}
        </Box>

        <Box className="row mb-2 mt-2">
          <Typography
            className="col"
            variant="h6"
            fontWeight="bold"
            color="primary"
          >
            Funções
          </Typography>
        </Box>

        <Box className="d-flex flex-wrap" sx={{ flexGrow: 4 }}>
          {categories
            .filter((d) => !d.deleted_at)
            .map((dc) => (
              <Box
                className="mb-2"
                sx={{ flex: '1 0 50%', ':nth-child(even)': { paddingLeft: 1 } }}
                key={`department_category_${dc.id}`}
              >
                <Box className="border p-2 pr-0 rounded position-relative">
                  <TextField
                    label="Nome"
                    variant="standard"
                    defaultValue={dc.name}
                    {...register(`department_category-${dc.id}-name`, {
                      required: true,
                    })}
                  ></TextField>
                  <TextField
                    label="Qtd. pessoas"
                    variant="standard"
                    defaultValue={dc.slots}
                    type="number"
                    className="ml-2"
                    {...register(`department_category-${dc.id}-slots`, {
                      required: true,
                      min: '1',
                    })}
                  ></TextField>

                  <IconButton
                    color="default"
                    sx={{
                      outline: '0 !important',
                      right: '0',
                      top: '0',
                      position: 'absolute',
                    }}
                    onClick={() => removeCategory(dc.id)}
                  >
                    <Close scale={40}></Close>
                  </IconButton>

                  <FormGroup className="d-flex flex-row mt-3">
                    {schedules
                      .filter((d) => !d.deleted_at)
                      .map((schedule) => {
                        const key = `department_category_schedule-${dc.id}-${schedule.id}`
                        return (
                          <FormControlLabel
                            sx={{ flex: '1 0 32%' }}
                            className="mb-0"
                            key={key}
                            control={
                              <Checkbox
                                defaultChecked={
                                  dc.department_categories_schedules
                                    .map((c) => c.schedule_id)
                                    .indexOf(schedule.id) > -1
                                }
                                {...register(key)}
                              />
                            }
                            label={formatSchedule(schedule)}
                          />
                        )
                      })}
                  </FormGroup>
                </Box>
              </Box>
            ))}

          <Box
            className="mb-2"
            sx={{ flex: '1 0 50%', ':nth-child(even)': { paddingLeft: 1 } }}
          >
            <Box className="border d-flex align-items-center justify-content-center rounded h-100">
              <IconButton
                onClick={() => addNewEmptyCategory(department)}
                className="my-auto"
                size="large"
                sx={{ outline: '0 !important' }}
              >
                <AddCircle fontSize="large" color="primary"></AddCircle>
              </IconButton>
            </Box>
          </Box>
        </Box>

        {(updateDepartmentData ||
          updateCategoriesData ||
          updateUserDepartmentData) && (
          <AlertWithTimeout
            severity="success"
            timeout={3000}
            sx={{ width: '100%' }}
            closable={true}
            closeCallback={() => resetAll()}
          >
            Ministério salvo com sucesso!
          </AlertWithTimeout>
        )}

        {(updateDepartmentError ||
          updateCategoriesError ||
          updateUserDepartmentDataError) && (
          <AlertWithTimeout
            severity="error"
            timeout={3000}
            sx={{ width: '100%' }}
            closable={true}
            closeCallback={() => resetAll()}
          >
            Não foi possível salvar as informações!
          </AlertWithTimeout>
        )}

        <hr />

        <Box className="mb-3 d-flex justify-content-end">
          <Button
            variant="contained"
            color="inherit"
            type="button"
            className="mr-2"
            onClick={() => reset()}
          >
            Cancelar
          </Button>
          <Button variant="contained" type="submit">
            Salvar
          </Button>
        </Box>
      </form>
    </Box>
  )
}
