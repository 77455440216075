import { useQuery } from '@apollo/client'
import {
  GetDepartmentDetailsQuery,
  GetSummaryQuery,
  GetSummaryQueryVariables,
} from '../_GeneratedGql/graphql'
import { GET_SUMMARY } from './GraphQL/GetSummaryQuery'
import { useEffect, useState } from 'react'
import { GET_DEPARTMENT_DETAILS } from '../Department/GraphQL/GetDepartmentDetailsQuery'
import { Box, Typography } from '@mui/material'
import Select from 'react-select'

import './MonthlySummary.css'

type CheckinSummary = GetSummaryQuery['department_categories_schedules'][0]['schedule']['checkins'][0]

export interface MonthlySummaryInput { }

export function MonthlySummary(_props: MonthlySummaryInput) {
  const { data } = useQuery<GetDepartmentDetailsQuery>(GET_DEPARTMENT_DETAILS, {
    fetchPolicy: 'network-only',
  })

  const [departmentId, setActiveDepartmentId] = useState<string>()

  useEffect(() => {
    if (data) {
      setActiveDepartmentId(data.departments[0].id)
    }
  }, [data])

  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const { data: scheduleData } = useQuery<
    GetSummaryQuery,
    GetSummaryQueryVariables
  >(GET_SUMMARY, {
    variables: {
      departmentId: departmentId || '',
      start: firstDay,
      end: lastDay,
    },
    fetchPolicy: 'network-only',
  })

  const formatDate = (dateString: string) => {
    const [_year, _month, day] = dateString.split('T')[0].split('-')

    return day
  }

  const groupBy = function(xs: any, key: any): any {
    return xs.reduce(function(rv: any, x: any) {
      ; (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  const groupedData: { [key: string]: CheckinSummary } = {}

  scheduleData?.department_categories_schedules.forEach((category) => {
    Object.values(
      groupBy(category.schedule.checkins, 'checkin_event_at')
    ).forEach((checkins: any) => {
      const idMap = new Map()

      const allCheckins = checkins.map((obj: any) => {
        const id = obj.department_category_id

        // Increment the index for the specific id
        const index = idMap.get(id) || 0
        idMap.set(id, index + 1)

        return { ...obj, index }
      })

      allCheckins.forEach((checkin: any) => {
        const key = `${checkin.department_category_id}-${checkin.index
          }-${checkin.checkin_event_at}`

        if (groupedData[key]) return

        groupedData[key] = checkin
      })
    })
  })

  const allCategories: {[key: string]: string} = scheduleData?.department_categories_schedules.reduce((acc, obj) => {
    return {...acc, [obj.department_category!.id]: obj.department_category!.name}
  }, {}) || {}

  // with offset of null elements to match sunday first
  // in a monthly grid
  let allMonthDates = Array.from(
    { length: firstDay.getUTCDay() }, 
    (_, i) => new Date(new Date(firstDay).setUTCDate(-i)).toISOString()
  ).reverse().concat(
    Array.from(
      { length: lastDay.getUTCDate() },
      (_, i) => new Date(new Date(firstDay).setUTCDate(i)).toISOString()
    )
  )

  // remove duplicates
  allMonthDates = [...new Set(allMonthDates)]

  const sameDay = (date1: string, date2: string) => {
    const [_year1, month1, day1] = date1.split('T')[0].split('-')
    const [_year2, month2, day2] = date2.split('T')[0].split('-')

    return day1 === day2 && month1 === month2
  }

  const period = (date: string) => {
    // brazilia time
    let hour = new Date(date).getUTCHours() -3
    let periodDate = ''

    if (hour >= 4 && hour <= 12) {
      periodDate = 'Manhã'
    } else if (hour > 12 && hour <= 18) {
      periodDate = 'Tarde'
    } else {
      periodDate = 'Noite'
    }

    return periodDate
  }

  const formatName = (name: string) => {
    const [firstName, ...lastName] = name.split(' ')

    if (lastName.length === 0) return firstName

    return `${firstName} ${lastName[0]?.charAt(0)}.`
  }

  const currentMonthPtBr = new Intl.DateTimeFormat('pt-BR', {
    month: 'long',
  }).format(new Date()).replace(/^\w/, (c) => c.toUpperCase())

  return (
    <Box className="container">
      <Typography
        variant="h5"
        fontWeight="bold"
        color="primary"
        className="mb-2"
        component={'p'}
      >
        Resumo do mês - {currentMonthPtBr}
      </Typography>

      {data && data.departments.length > 1 && (
        <Select
          defaultValue={data!.departments[0]}
          onChange={(department) => setActiveDepartmentId(department!.id)}
          options={data!.departments}
          getOptionLabel={(opt: any) => opt.name}
          getOptionValue={(opt: any) => opt.name}
          className="mt-2 w-100"
          isSearchable
        />
      )}
      
      <div className="grid-rows py-2 mt-2">
        <strong className="text-center">Dom</strong>
        <strong className="text-center">Seg</strong>
        <strong className="text-center">Ter</strong>
        <strong className="text-center">Qua</strong>
        <strong className="text-center">Qui</strong>
        <strong className="text-center">Sex</strong>
        <strong className="text-center">Sáb</strong>

        {allMonthDates.map((date) => 
          <div className="month-day">
            <span className="day">{formatDate(date)}</span>
            {Object.entries(groupBy(Object.values(groupedData)
              .filter(v => sameDay(v.checkin_event_at, date))
              .sort((a, b) => new Date(a.checkin_event_at).getTime() - new Date(b.checkin_event_at).getTime()), 'checkin_event_at'))
              .flatMap(([checkin_event_at, values]: any) => 
                <div className="container-day">
                  <hr/>

                  <span className="day-period">{period(checkin_event_at)}</span>

                  {values.map((value: CheckinSummary) => {
                    if (!value?.contact?.name) return <></>
                    return (
                      <span key={`${value.id}`} className="name-holder">

                        <span className={`name status-${value?.status}`}>{formatName(value?.contact?.name)}</span>
                        <span className="category">
                          {` (${allCategories[value.department_category_id]})`}
                        </span>
                      </span>
                    )
                  })}
                </div>
            )}
          </div>
        )}
      </div>
    </Box >
  )
}
