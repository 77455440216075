export const DaysMap: { [day: string]: string } = {
  Sunday: 'Domingo',
  Monday: 'Segunda',
  Tuesday: 'Terça',
  Wednesday: 'Quarta',
  Thursday: 'Quinta',
  Friday: 'Sexta',
  Saturday: 'Sábado',
}

export const getMinutesDiff = (time1: string, time2: string): number => {
  // Parse the times as Date objects
  const date1 = new Date(`2000-01-01T${time1}:00`)
  const date2 = new Date(`2000-01-01T${time2}:00`)

  // Calculate the difference in milliseconds
  const diffMs = date2.getTime() - date1.getTime()

  // Convert the difference to minutes
  const diffMins = Math.round(diffMs / 60000)

  return diffMins
}

export const toTimezoneHour = (hourLocalTime: string, offset = -3): string => {
  const refDate = new Date(
    new Date(
      new Date().toISOString().split('T')[0] + 'T' + hourLocalTime
    ).toISOString()
  )

  return new Date(refDate.setUTCHours(refDate.getUTCHours() + offset))
    .toISOString()
    .split('T')[1]
    .substring(0, 8)
}

/**
 * Converts date string to dd/MM/yyyy
 * @param input 2020-04-29
 * @returns 29/04/2020
 */
export const dateToDDMMYYYY = (input: string): string => {
  const date = new Date(`${input}T00:00:00Z`)
  const day = date.getUTCDate().toString().padStart(2, '0')
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = date.getUTCFullYear().toString()

  return `${day}/${month}/${year}`
}

export const formatBirthDate = (date: string | null): string | null => {
  if (!date) return null

  return new Date(`${date}T12:00:00.000Z`).toISOString()
}

export const getOffset = (offset: number = -3): string => {
  const sign = offset < 0 ? '-' : '+'

  const hoursFromOffset = `${Math.abs(offset)}`.padStart(2, '0')

  return `${sign}${hoursFromOffset}:00`
}
