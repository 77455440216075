import React, { useState } from 'react'
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import { Logout, MenuOpenOutlined, MenuOutlined, Settings } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import { DepartmentManagerContactModal } from '../Contact/DepartmentManagerContactModal'

import './Header.css'

type HeaderProps = {
  path: string
}

export default function Header({ path }: HeaderProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleConfig = () => {
    setAnchorEl(null)
    setContactModalOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleExit = () => {
    setAnchorEl(null)

    localStorage.removeItem('token')
    window.location.href = '/'
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      bgcolor="#000000"
      marginBottom={2}
    >
      <Box
        className="container"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <img
            src="./images/logo-black.svg"
            className="d-flex mx-auto"
            style={{ width: '110px' }}
            alt="Login"
          />
        </Box>
        <IconButton
          className="d-block d-md-none"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          size="small"
          sx={{ ml: 2, color: 'white' }}
        >
          {mobileMenuOpen ? (<MenuOpenOutlined sx={{ width: 34, height: 34 }} />) : (<MenuOutlined sx={{ width: 34, height: 34 }} />)}
        </IconButton>
        <Box
          className={mobileMenuOpen ? "mobile-menu d-md-flex" : "d-none d-md-flex"}
          onClick={() => setMobileMenuOpen(false)}
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <NavLink
            className={`menu-item selected-${path === 'calendar'}`}
            to={'/'}
          >
            Calendário
          </NavLink>
          <NavLink
            className={`menu-item selected-${path === 'summary'}`}
            to={'/summary'}
          >
            Resumo do mês
          </NavLink>
          <NavLink
            className={`menu-item selected-${path === 'volunteers'}`}
            to={'/volunteers'}
          >
            Voluntários
          </NavLink>
          <NavLink
            className={`menu-item selected-${path === 'departments'}`}
            to={'/departments'}
          >
            Ministério
          </NavLink>
          <Tooltip className="d-none d-md-block" title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 34, height: 34 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleConfig}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Configurações
        </MenuItem>
        <MenuItem onClick={handleExit}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>

      <DepartmentManagerContactModal
        open={contactModalOpen}
        setOpen={setContactModalOpen}
      />
    </Box>
  )
}
