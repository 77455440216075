import { CalendarEvent } from 'kalend'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material'
import { CalendarEventDetails } from './CalendarEventDetails'
import { useMutation } from '@apollo/client'
import {
  UpsertCheckinInput,
  UpsertCheckinsMutation,
  UpsertCheckinsMutationVariables,
} from '../_GeneratedGql/graphql'
import { UPSERT_CHECKINS_MUTATION } from './GraphQL/UpsertCheckinsMutation'
import { useState } from 'react'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'
import { AlertWithTimeout } from '../Utils/AlertWithTimeout'

export interface CalendarEventDetailsModalInput {
  selectedEvent: CalendarEvent | null
  setSelectedEvent: Function
}

export function CalendarEventDetailsModal({
  selectedEvent,
  setSelectedEvent,
}: CalendarEventDetailsModalInput) {
  const [confirmCheckins, { loading, error, data: confirmCheckinsData }] =
    useMutation<UpsertCheckinsMutation, UpsertCheckinsMutationVariables>(
      UPSERT_CHECKINS_MUTATION
    )

  const [originalCheckins, setOriginalCheckins] = useState<
    UpsertCheckinInput[]
  >([])
  const [checkins, setCheckins] = useState<UpsertCheckinInput[]>([])

  const save = async () => {
    await confirmCheckins({ variables: { objects: checkins } })

    setOriginalCheckins(checkins)
  }

  const cancel = async () => {
    const selectedEventCopy = selectedEvent
    setSelectedEvent(null)
    setTimeout(() => setSelectedEvent(selectedEventCopy), 0)
  }

  const arraysEqual = (a: any[], b: any[]) => {
    if (a.length === 0 && b.length === 0) return true
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    return a.every((element, index) => {
      const otherElement = b[index]
      if (typeof element !== typeof otherElement) return false
      if (typeof element !== 'object') return element === otherElement
      return Object.keys(element).every(
        (key) => element[key] === otherElement[key]
      )
    })
  }

  return (
    <Dialog
      open={!!selectedEvent}
      fullScreen={false}
      onClose={() => {
        setSelectedEvent(null)
      }}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      {selectedEvent && (
        <>
          <DialogTitle>{selectedEvent?.summary}</DialogTitle>

          <DialogContent sx={{ minWidth: '400px' }}>
            {loading && <AbsoluteCircularProgress />}
            <CalendarEventDetails
              selectedEvent={selectedEvent}
              setCheckins={setCheckins}
              setOriginalCheckins={setOriginalCheckins}
            />
            {!!error && (
              <AlertWithTimeout
                severity="error"
                timeout={3000}
                className="mr-auto w-100"
              >
                Erro ao salvar checkins
              </AlertWithTimeout>
            )}
            {!!confirmCheckinsData && (
              <AlertWithTimeout
                timeout={3000}
                severity="success"
                hidden={!confirmCheckinsData}
                className="mr-auto w-100"
              >
                Checkins salvos com sucesso
              </AlertWithTimeout>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              type="submit"
              color="inherit"
              onClick={cancel}
              variant="contained"
              disabled={
                loading ||
                checkins.length === 0 ||
                arraysEqual(originalCheckins, checkins)
              }
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={save}
              variant={loading ? 'text' : 'contained'}
              className={`loading-${loading}`}
              disabled={
                loading ||
                checkins.length === 0 ||
                arraysEqual(originalCheckins, checkins)
              }
            >
              Salvar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
