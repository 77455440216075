import { gql } from '@apollo/client'

export const GET_CONTACT_BY_USER_ID = gql`
  query GetContactByUserId($id: String!) {
    contacts(where: { user_id: { _eq: $id } }) {
      id
      name
      phone
      birth_date
    }
  }
`
