import { TextField, Button } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useForm, Controller } from 'react-hook-form'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { deformatPhone, formatPhoneNumber } from '../Utils/Phone'
import {
  GetContactByUserIdQuery,
  UpdateContactByUserIdMutation,
  UpdateContactByUserIdMutationVariables,
} from '../_GeneratedGql/graphql'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'
import { useMutation } from '@apollo/client'
import { AlertWithTimeout } from '../Utils/AlertWithTimeout'
import { UPDATE_CONTACT_BY_USER_ID } from './GraphQL/UpdateContactByUserId'
import { getUserId } from '../Utils/Auth'
import { formatBirthDate } from '../Utils/Days'

type Contact = GetContactByUserIdQuery['contacts'][0]

type DepartmentManagerContactFormInput = {
  contact: Contact
}

export function DepartmentManagerContactForm({
  contact,
}: DepartmentManagerContactFormInput) {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: contact?.name,
      phone: formatPhoneNumber(contact?.phone),
      birth_date: formatBirthDate(contact?.birth_date),
    },
  })

  const [updateContactMutation, { data, error, loading }] = useMutation<
    UpdateContactByUserIdMutation,
    UpdateContactByUserIdMutationVariables
  >(UPDATE_CONTACT_BY_USER_ID)

  const onSubmit = async (data: any) => {
    const birth_date = new Date(data.birth_date).toISOString().split('T')[0]

    await updateContactMutation({
      variables: {
        id: getUserId()!,
        set: { phone: deformatPhone(data.phone), name: data.name, birth_date },
      },
    })
  }

  return (
    <form
      className="py-2"
      style={{ textAlign: 'end' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        id="name"
        type="text"
        label="Nome"
        className="mb-3 w-100"
        InputLabelProps={{
          shrink: true,
        }}
        {...register('name', {
          required: true,
        })}
      />
      <Controller
        control={control}
        rules={{
          validate: {
            validPhone: (value) => matchIsValidTel(value) || 'Celular inválido',
          },
        }}
        render={({ field, fieldState }) => (
          <MuiTelInput
            {...field}
            defaultCountry="BR"
            helperText={fieldState.invalid && 'Celular inválido'}
            error={fieldState.invalid}
            onlyCountries={['BR']}
            label="Celular"
            variant="outlined"
            className="w-100"
          />
        )}
        defaultValue={formatPhoneNumber(getValues('phone'))}
        name="phone"
      />

      <Controller
        control={control}
        name="birth_date"
        rules={{ required: false }}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data de Nascimento"
              onChange={(date) => field.onChange(date)}
              inputFormat="dd/MM/yyyy"
              className="w-100 mt-3 mb-2"
              value={field.value}
              renderInput={(input) => (
                <TextField
                  {...input}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />

      {!!error && (
        <AlertWithTimeout
          severity="error"
          timeout={3000}
          className="mr-auto w-100"
        >
          Erro ao salvar informações
        </AlertWithTimeout>
      )}
      {!!data && (
        <AlertWithTimeout
          timeout={3000}
          severity="success"
          hidden={!data}
          className="mr-auto w-100"
        >
          Informações salvas com sucesso!
        </AlertWithTimeout>
      )}

      {loading && <AbsoluteCircularProgress />}

      <Button
        className="mt-2 ml-auto"
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isDirty}
      >
        Salvar
      </Button>
    </form>
  )
}
