import { PAGE_LIMIT, VolunteersTable } from './VolunteersTable'
import { VolunteersSearch } from './VolunteersSearch'
import {
  GetVolunteersByDepartmentQuery,
  GetVolunteersByDepartmentQueryVariables,
} from '../_GeneratedGql/graphql'
import { useQuery } from '@apollo/client'
import { GET_VOLUNTEERS_BY_DEPARTMENT } from './GraphQL/GetVolunteersByDepartmentQuery'
import { useEffect, useState } from 'react'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'
import { Alert, Box, Typography } from '@mui/material'
import EditVolunteersDialog from './EditVolunteersDialog'
import { usePreviousNonNullish } from '../Utils/UsePreviousNonNullish'

type Department = GetVolunteersByDepartmentQuery['departments'][0]
type Contact =
  GetVolunteersByDepartmentQuery['departments'][0]['departments_contacts_aggregate']['nodes'][0]['contact']

export function VolunteersPage() {
  const { loading, data, error, refetch, variables } = useQuery<
    GetVolunteersByDepartmentQuery,
    GetVolunteersByDepartmentQueryVariables
  >(GET_VOLUNTEERS_BY_DEPARTMENT, {
    variables: { offset: 0, limit: PAGE_LIMIT, search: '%%' },
    fetchPolicy: 'network-only',
  })

  const prevData = usePreviousNonNullish(data)

  const [selectedDepartment, setSelectedDepartment] = useState<Department>()
  const [open, setOpen] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [contact, setContact] = useState<Contact>()

  const handleOpenEditDialog = (
    selectedContact: Contact,
    isCreating: boolean
  ) => {
    setIsCreating(isCreating)
    setContact(selectedContact)
    setOpen(true)
  }

  const handleCloseEditDialog = async () => {
    if (isCreating) {
      await refetch(variables)
    }
    setOpen(false)
  }

  const onRefetch = (newVariables: GetVolunteersByDepartmentQueryVariables) => {
    refetch({ ...variables, ...newVariables })
  }

  useEffect(() => {
    if (data && !prevData) {
      setSelectedDepartment(data.departments[0])
      return
    }

    if (data && selectedDepartment) {
      setSelectedDepartment(
        data.departments.find((d) => d.id === selectedDepartment.id)
      )
    }
  }, [data, prevData, selectedDepartment, setSelectedDepartment])

  return (
    <Box className="container">
      <Typography
        variant="h5"
        fontWeight="bold"
        color="primary"
        className="mb-2"
        component={'p'}
      >
        Gerenciar voluntários
      </Typography>

      <VolunteersSearch
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        departments={data?.departments}
        handleOpenEditDialog={handleOpenEditDialog}
        refetch={onRefetch}
      />

      {selectedDepartment && !loading && (
        <VolunteersTable
          handleOpenEditDialog={handleOpenEditDialog}
          selectedDepartment={selectedDepartment}
          refetch={onRefetch}
        />
      )}

      {open && contact && selectedDepartment && (
        <EditVolunteersDialog
          isCreating={isCreating}
          open={open}
          contact={contact}
          selectedDepartment={selectedDepartment}
          handleCloseEditDialog={handleCloseEditDialog}
        />
      )}

      {!!error && (
        <Alert severity="error" className="mr-auto w-100">
          Erro ao carregar voluntários
        </Alert>
      )}

      {loading && <AbsoluteCircularProgress />}
    </Box>
  )
}
