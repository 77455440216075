import { Box, Button, IconButton, InputBase, Paper } from '@mui/material'
import Select from 'react-select'
import { Add, Search } from '@mui/icons-material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  GetVolunteersByDepartmentQuery,
  GetVolunteersByDepartmentQueryVariables,
} from '../_GeneratedGql/graphql'
import { ulid } from 'ulid'

type Department = GetVolunteersByDepartmentQuery['departments'][0]
type Contact =
  GetVolunteersByDepartmentQuery['departments'][0]['departments_contacts_aggregate']['nodes'][0]['contact']

interface VolunteersSearchInput {
  departments?: Department[]
  selectedDepartment?: Department
  setSelectedDepartment: Dispatch<SetStateAction<Department | undefined>>
  handleOpenEditDialog: (contact: Contact, isCreating: boolean) => void
  refetch: (variables: GetVolunteersByDepartmentQueryVariables) => void
}

export function VolunteersSearch({
  selectedDepartment,
  setSelectedDepartment,
  departments,
  handleOpenEditDialog,
  refetch,
}: VolunteersSearchInput) {
  const [search, setSearch] = useState<string>('%%')

  const createNewVolunteer = () => {
    const contact: Contact = {
      id: ulid(),
      name: '',
      phone: '',
      birth_date: null,
      departments_contacts_aggregate: { nodes: [] },
    }

    handleOpenEditDialog(contact, true)
  }

  useEffect(() => {
    const timeOutId = setTimeout(
      () => refetch({ search } as GetVolunteersByDepartmentQueryVariables),
      500
    )
    return () => clearTimeout(timeOutId)
  }, [search, refetch])

  return (
    <Box className="row mb-3">
      <Box className="col-12 col-md-6">
        <Box className="d-flex">
          {departments?.length && (
            <Select
              onChange={(department) =>
                setSelectedDepartment(department as Department)
              }
              value={selectedDepartment}
              options={departments}
              getOptionLabel={(opt: Department) => opt.name}
              getOptionValue={(opt: Department) => opt.name}
              className="w-100"
              isSearchable
            />
          )}
          <Button
            className="ml-2 pr-4"
            variant="contained"
            onClick={createNewVolunteer}
            sx={{ ':focus': { outline: 'none' } }}
            startIcon={<Add className="ml-2" />}
          >
            Adicionar
          </Button>
        </Box>
      </Box>
      <Box className="col-12 col-md-6">
        <Paper
          className="ml-auto"
          sx={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: 400,
            boxShadow: 'none',
            border: '1px solid hsl(0, 0%, 80%)',
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, p: 0 }}
            placeholder="Buscar voluntário"
            onChange={(ev) => setSearch(`%${ev.target.value || ''}%`)}
          />
          <IconButton type="button" aria-label="search" disabled>
            <Search />
          </IconButton>
        </Paper>
      </Box>
    </Box>
  )
}
