import { useRef, useEffect } from 'react'

export function usePreviousNonNullish<T>(value: T): T {
  const ref = useRef<T>(value)
  useEffect(() => {
    if (value !== null && value !== undefined) {
      ref.current = value
    }
  })
  return ref.current
}
