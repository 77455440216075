import 'kalend/dist/styles/index.css'
import Kalend, { CalendarEvent, CalendarView } from 'kalend'
import { useQuery } from '@apollo/client'
import { Box, CircularProgress } from '@mui/material'
import { useState } from 'react'
import { PageChangeData } from 'kalend/common/interface'
import { GetSchedulesQuery } from '../_GeneratedGql/graphql'
import { GET_SCHEDULES } from './GraphQL/GetSchedulesQuery'
import { CalendarEventDetailsModal } from './CalendarEventDetailsModal'
import { getOffset } from '../Utils/Days'

const DaysMapNumber: { [day: number]: string } = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export interface CalendarSummaryInput { }

export function CalendarSummary(_props: CalendarSummaryInput) {
  const { loading, error, data } = useQuery<GetSchedulesQuery>(GET_SCHEDULES, {
    fetchPolicy: 'network-only',
  })
  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null)

  if (loading)
    return (
      <Box className="d-flex justify-content-center">
        <CircularProgress className="p-2" />
      </Box>
    )
  if (error) return <Box className="text-center">Erro ao carregar eventos</Box>

  const onEventClick = (event: CalendarEvent) => {
    setSelectedEvent(event)
  }

  const buildEvents = (startDateISO: string, endDateISO: string) => {
    const getDaysArray = function(start: Date, end: Date) {
      for (
        var arr = [], dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt))
      }
      return arr
    }

    const daylist = getDaysArray(
      new Date(startDateISO),
      new Date(endDateISO)
    ).map((v) => v.toISOString().slice(0, 10))

    const events = daylist
      .map((day) => {
        return data!.schedules.map((s) => {
          const startAt = `${day}T${s.start_hour}${getOffset(
            s.department.timezone_offset
          )}`
          const startAtDate = new Date(startAt)

          if (s.deleted_at && startAtDate > new Date(s.deleted_at)) return null
          if (new Date(s.created_at) > startAtDate) return null
          if (DaysMapNumber[startAtDate.getDay()] !== s.week_day) return null

          const endAt = new Date(
            startAtDate.setMinutes(startAtDate.getMinutes() + s.duration)
          ).toISOString()

          return {
            resizable: false,
            id: `${s.id}//${startAt}`,
            startAt,
            endAt,
            summary: s.department.name,
            color: s.department.color,
            scheduleId: s.id,
          }
        }) as CalendarEvent[]
      })
      .flat()
      .filter((d) => d)

    setEvents(events)
  }

  const onPageChange = ({ rangeFrom, rangeTo }: PageChangeData) => {
    buildEvents(rangeFrom, rangeTo)
  }

  return (
    <div className="container" style={{ height: '80vh' }}>
      <Kalend
        onEventClick={onEventClick}
        hourHeight={60}
        disabledDragging={true}
        resizeDisabledConditions={{ resizable: false }}
        timezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
        events={events}
        autoScroll={true}
        colors={{
          light: { primaryColor: 'var(--primary)' },
          dark: { primaryColor: 'var(--primary)' },
        }}
        initialDate={new Date().toISOString()}
        initialView={CalendarView.AGENDA}
        disabledViews={[CalendarView.THREE_DAYS]}
        onPageChange={onPageChange}
        timeFormat={'24'}
        weekDayStart={'Sunday'}
        language={'ptBR'}
      />

      <CalendarEventDetailsModal
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />
    </div>
  )
}
