import { Box, CircularProgress } from '@mui/material'

export function AbsoluteCircularProgress() {
  const styles = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff90',
    zIndex: 555,
  }

  return (
    <Box className="d-flex justify-content-center" sx={styles}>
      <CircularProgress />
    </Box>
  )
}
