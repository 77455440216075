import { gql } from '@apollo/client'

export const GET_VOLUNTEERS_BY_DEPARTMENT = gql`
  query GetVolunteersByDepartment(
    $limit: Int!
    $offset: Int!
    $search: String
  ) {
    departments(order_by: { name: asc_nulls_last }) {
      id
      name
      department_categories(where: { deleted_at: { _is_null: true } }) {
        id
        name
      }
      contacts_count: departments_contacts_aggregate(
        distinct_on: contact_id
        where: {
          _or: [
            { contact: { name: { _ilike: $search } } }
            { contact: { phone: { _ilike: $search } } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      departments_contacts_aggregate(
        distinct_on: contact_id
        limit: $limit
        offset: $offset
        where: {
          _or: [
            { contact: { name: { _ilike: $search } } }
            { contact: { phone: { _ilike: $search } } }
          ]
        }
      ) {
        nodes {
          contact {
            id
            name
            phone
            birth_date
            departments_contacts_aggregate(
              distinct_on: department_category_id
              limit: $limit
              offset: $offset
            ) {
              nodes {
                department_category_id
              }
            }
          }
        }
      }
    }
  }
`
