import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { GET_CONTACT_BY_USER_ID } from './GraphQL/GetContactByUserId'
import { getUserId } from '../Utils/Auth'
import { useEffect } from 'react'
import {
  GetContactByUserIdQuery,
  GetContactByUserIdQueryVariables,
} from '../_GeneratedGql/graphql'
import { DepartmentManagerContactForm } from './DepartmentManagerContactForm'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'

type DepartmentManagerContactModalInput = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function DepartmentManagerContactModal({
  open,
  setOpen,
}: DepartmentManagerContactModalInput) {
  const [getContactQuery, { data, loading }] = useLazyQuery<
    GetContactByUserIdQuery,
    GetContactByUserIdQueryVariables
  >(GET_CONTACT_BY_USER_ID)

  useEffect(() => {
    getContactQuery({
      variables: { id: getUserId()! },
    })
  }, [open, getContactQuery])

  return (
    <Dialog
      open={!!open}
      fullScreen={false}
      onClose={() => setOpen(false)}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Editar contato</DialogTitle>

      <DialogContent>
        {loading && <AbsoluteCircularProgress />}

        {data?.contacts[0] && (
          <DepartmentManagerContactForm contact={data?.contacts[0]} />
        )}
      </DialogContent>
    </Dialog>
  )
}
