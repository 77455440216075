import { gql } from '@apollo/client'

export const GET_DEPARTMENT_DETAILS = gql`
  query GetDepartmentDetails {
    departments(order_by: { name: asc_nulls_last }) {
      id
      name
      color
      timezone_offset
      contact {
        id
        name
        phone
        user_id
      }
      schedules(where: { deleted_at: { _is_null: true } }) {
        id
        start_hour
        duration
        week_day
        department_id
        deleted_at
      }
      department_categories(where: { deleted_at: { _is_null: true } }) {
        id
        name
        slots
        department_id
        deleted_at
        department_categories_schedules {
          schedule_id
        }
      }
    }
  }
`
