import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './GraphqlClient'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import red from '@mui/material/colors/red'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const theme = createTheme({
  palette: {
    primary: { ...red, '500': '#C11E2F' },
  },
})

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
)
