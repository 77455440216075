import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'
import { Delete, Edit } from '@mui/icons-material'
import {
  IconButton,
  TableFooter,
  TablePagination,
  Typography,
  styled,
} from '@mui/material'
import {
  GetVolunteersByDepartmentQuery,
  GetVolunteersByDepartmentQueryVariables,
  RemoveVolunteerMutation,
  RemoveVolunteerMutationVariables,
} from '../_GeneratedGql/graphql'
import { formatPhoneNumber } from '../Utils/Phone'
import { dateToDDMMYYYY } from '../Utils/Days'
import { useMutation } from '@apollo/client'
import { REMOVE_VOLUNTEER_MUTATION } from './GraphQL/RemoveVolunteerMutation'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { useEffect, useState } from 'react'

export const PAGE_LIMIT = 10

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    boxShadow: '1px solid #ccc',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

type Department = GetVolunteersByDepartmentQuery['departments'][0]
type Contact =
  GetVolunteersByDepartmentQuery['departments'][0]['departments_contacts_aggregate']['nodes'][0]['contact']

interface VolunteersTableInput {
  selectedDepartment: Department
  refetch: (variables: GetVolunteersByDepartmentQueryVariables) => void
  handleOpenEditDialog: (contact: Contact, isCreating: boolean) => void
}

export function VolunteersTable({
  selectedDepartment,
  handleOpenEditDialog,
  refetch,
}: VolunteersTableInput) {
  const [removeVolunteerMutation, { loading }] = useMutation<
    RemoveVolunteerMutation,
    RemoveVolunteerMutationVariables
  >(REMOVE_VOLUNTEER_MUTATION)

  const [limit] = useState<number>(PAGE_LIMIT)
  const [offset, setOffset] = useState<number>()
  const [page, setPage] = useState<number>(0)

  const removeVolunteer = async (contact: Contact) => {
    const variables: RemoveVolunteerMutationVariables = {
      departmentId: selectedDepartment.id,
      id: contact.id,
    }

    await removeVolunteerMutation({ variables })
    refetch({ limit, offset } as GetVolunteersByDepartmentQueryVariables)
  }

  const nodes = selectedDepartment.departments_contacts_aggregate.nodes
  const hasContacts = nodes.length > 0

  useEffect(() => {
    const newOffset = limit * page
    setOffset(newOffset)
    refetch({ limit, offset: newOffset })
  }, [page, setOffset, limit, refetch])

  if (!hasContacts) {
    return (
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{ color: 'gray' }}
          className="ml-3 d-flex flex-column text-center"
        >
          <img src="/images/wip.png" alt="wip" style={{ marginTop: 100 }}></img>
          Nenhum voluntário encontrado
        </Typography>
      </Box>
    )
  }

  return (
    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 'none', border: '1px solid hsl(0, 0%, 90%)' }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left">Nome</StyledTableCell>
              <StyledTableCell align="right">Celular</StyledTableCell>
              <StyledTableCell align="right">
                Data de Nascimento
              </StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {nodes.map((row) => (
              <StyledTableRow
                key={row.contact.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.contact.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatPhoneNumber(row.contact.phone, true)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {(row.contact.birth_date &&
                    dateToDDMMYYYY(row.contact.birth_date)) ||
                    'Não informado'}
                </StyledTableCell>
                <StyledTableCell sx={{ marginLeft: '10px' }} align="right">
                  <IconButton
                    onClick={() => handleOpenEditDialog(row.contact, false)}
                  >
                    <Edit sx={{ fontSize: '20px' }} />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '20px' }} align="right">
                  <IconButton onClick={() => removeVolunteer(row.contact)}>
                    <Delete sx={{ fontSize: '20px' }} />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>

          <TableFooter sx={{ borderTop: '1px solid #e0e0e0' }}>
            <StyledTableRow>
              <TablePagination
                rowsPerPageOptions={[limit]}
                count={selectedDepartment.contacts_count.aggregate!.count}
                rowsPerPage={limit}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'voluntários por página',
                  },
                  native: true,
                }}
                onPageChange={(_ev, page) => setPage(page)}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {loading && <AbsoluteCircularProgress />}
    </Box>
  )
}
