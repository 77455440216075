import { Button, TextField, Box } from '@mui/material'
import { useState } from 'react'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'

import './LoginPage.css'
import { useMutation } from '@apollo/client'
import { LOGIN_MUTATION } from './GraphQL/LoginMutation'
import { LoginMutation, LoginMutationVariables } from '../_GeneratedGql/graphql'

type LoginPageInput = {}

function LoginPage(_props: LoginPageInput) {
  const [password, setPassword] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [hasError, setError] = useState<boolean>(false)
  const [loginMutation, { error, loading }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LOGIN_MUTATION)

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!password || !email) return

    setError(false)

    const input = { email, password }

    try {
      const { data } = await loginMutation({
        variables: { input },
      })
      const token = data?.login?.token
      if (token) {
        localStorage.setItem('token', token)
        window.location.href = '/'
      } else {
        setError(true)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Box className="login-page">
      <Box className="login-box">
        <Box className="illustration-wrapper">
          <img
            src="./images/logo-black.svg"
            className="d-flex mx-auto"
            style={{ width: '200px' }}
            alt="Login"
          />
        </Box>
        <Box className="login-form px-5 pb-5">
          <h2 className="form-title">Bem vindo de volta</h2>
          <p>Login para o Painel</p>

          <form onSubmit={handleLogin}>
            <TextField
              id="email"
              type="email"
              label="Email"
              variant="outlined"
              className="mb-3 w-100"
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value)
              }}
            />
            <TextField
              id="password"
              type="password"
              label="Senha"
              variant="outlined"
              className="mb-3 w-100"
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value)
              }}
            />

            <Button
              type="submit"
              color="primary"
              variant={loading ? 'text' : 'contained'}
              className={`w-100 loading-${loading}`}
              disabled={loading}
            >
              ENTRAR
            </Button>
          </form>

          {loading && <AbsoluteCircularProgress />}
          {(error || hasError) && (
            <p className="text-danger">Não foi possível entrar</p>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default LoginPage
