import { gql } from '@apollo/client'

export const UPDATE_DEPARTMENT_USER_MUTATION = gql`
  mutation UpdateUserDepartments(
    $departmentId: String!
    $contactId: String!
    $userId: String!
  ) {
    delete_user_departments(where: { department_id: { _eq: $departmentId } }) {
      affected_rows
    }
    insert_user_departments_one(
      object: { department_id: $departmentId, user_id: $userId }
    ) {
      id
    }
    update_departments_by_pk(
      pk_columns: { id: $departmentId }
      _set: { contact_id: $contactId }
    ) {
      id
    }
  }
`
