export const formatPhoneNumber = (
  phoneNumber?: string,
  withParenthesis: boolean = false
) => {
  if (!phoneNumber) return ''

  const countryCode = `${phoneNumber}`.substring(0, 2)
  const areaCode = `${phoneNumber}`.substring(2, 4)
  const firstFive = `${phoneNumber}`.substring(4, 9)
  const lastFour = `${phoneNumber}`.substring(9)

  if (withParenthesis) {
    return `+${countryCode} (${areaCode}) ${firstFive} ${lastFour}`
  }

  return `+${countryCode} ${areaCode} ${firstFive} ${lastFour}`
}

export const deformatPhone = (phoneNumber: string) => {
  const deformated = `${phoneNumber}`.replace(/[^0-9]+/g, '')

  if (deformated.length === 12) {
    const firstPart = deformated.slice(0, 4)
    const lastPart = deformated.slice(4)

    return `${firstPart}9${lastPart}`
  }

  return deformated
}
