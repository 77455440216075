import { gql } from '@apollo/client'

export const UPDATE_DEPARTMENT_MUTATION = gql`
  mutation UpdateDepartment(
    $id: String!
    $departmentName: String!
    $departmentColor: String!
    $organizationId: String!
    $depCats: [department_categories_insert_input!]!
    $schedules: [schedules_insert_input!]!
  ) {
    insert_departments_one(
      object: {
        id: $id
        color: $departmentColor
        organization_id: $organizationId
        name: $departmentName
      }
      on_conflict: {
        constraint: departments_pkey
        update_columns: [color, name]
      }
    ) {
      id
    }
    insert_department_categories(
      objects: $depCats
      on_conflict: {
        constraint: department_categories_pkey
        update_columns: [name, slots, deleted_at]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
    insert_schedules(
      objects: $schedules
      on_conflict: {
        constraint: schedules_pkey
        update_columns: [duration, start_hour, week_day, deleted_at]
      }
    ) {
      affected_rows
    }
  }
`
