import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../Header/Header'

interface PrivateRouteProps {
  element: JSX.Element
  path: string
}

export default function PrivateRoute({ path, element }: PrivateRouteProps) {
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      navigate('/login')
    }
  }, [navigate])

  return (
    <>
      <Header path={path} />
      {element}
    </>
  )
}
