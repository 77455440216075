import { gql } from '@apollo/client'

export const UPSERT_VOLUNTEER_MUTATION = gql`
  mutation UpsertVolunteer(
    $id: String!
    $name: String!
    $phone: String!
    $birthDate: date!
    $departmentId: String!
    $categories: [departments_contacts_insert_input!]!
  ) {
    insert_contacts_one(
      object: { id: $id, phone: $phone, birth_date: $birthDate, name: $name }
      on_conflict: {
        constraint: contacts_pkey
        update_columns: [birth_date, name, phone]
      }
    ) {
      id
      birth_date
      phone
      name
    }
    delete_departments_contacts(
      where: {
        department_id: { _eq: $departmentId }
        contact: { phone: { _eq: $phone } }
      }
    ) {
      affected_rows
    }
    insert_departments_contacts(objects: $categories) {
      affected_rows
    }
  }
`
