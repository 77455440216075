import { gql } from '@apollo/client'

export const UPDATE_DEPARTMENT_CATEGORY_SCHEDULES_MUTATION = gql`
  mutation UpdateDepartmentCategorySchedules(
    $departmentId: String!
    $objects: [department_categories_schedules_insert_input!]!
  ) {
    delete_department_categories_schedules(
      where: { department_id: { _eq: $departmentId } }
    ) {
      affected_rows
    }
    insert_department_categories_schedules(objects: $objects) {
      affected_rows
    }
  }
`
