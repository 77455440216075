import { useForm, Controller } from 'react-hook-form'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Select from 'react-select'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material'
import {
  Departments_Contacts_Insert_Input,
  GetVolunteerSuggestionsQuery,
  GetVolunteerSuggestionsQueryVariables,
  GetVolunteersByDepartmentQuery,
  UpsertVolunteerMutation,
  UpsertVolunteerMutationVariables,
} from '../_GeneratedGql/graphql'
import { deformatPhone, formatPhoneNumber } from '../Utils/Phone'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_VOLUNTEER_SUGGESTIONS } from './GraphQL/GetVolunteerSuggestionsQuery'
import { AbsoluteCircularProgress } from '../Utils/AbsoluteCircularProgress'
import { useEffect } from 'react'
import { UPSERT_VOLUNTEER_MUTATION } from './GraphQL/UpsertVolunteerMutation'
import { AlertWithTimeout } from '../Utils/AlertWithTimeout'
import { formatBirthDate } from '../Utils/Days'

type Department = GetVolunteersByDepartmentQuery['departments'][0]
type Category =
  GetVolunteersByDepartmentQuery['departments'][0]['department_categories'][0]

type Contact =
  GetVolunteersByDepartmentQuery['departments'][0]['departments_contacts_aggregate']['nodes'][0]['contact']

export interface EditVolunteersDialogInput {
  contact: Contact
  selectedDepartment: Department
  open: boolean
  isCreating: boolean
  handleCloseEditDialog: () => void
}

export default function EditVolunteersDialog({
  contact,
  selectedDepartment,
  handleCloseEditDialog,
  open,
  isCreating,
}: EditVolunteersDialogInput) {
  const defaultSelectedCategories =
    contact.departments_contacts_aggregate.nodes.map(
      (d) => d.department_category_id
    )

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      id: contact.id,
      name: contact.name,
      phone: formatPhoneNumber(contact.phone),
      birth_date: formatBirthDate(contact.birth_date),
      categories: defaultSelectedCategories,
    },
  })

  const [getVolunteersQuery, { loading, data }] = useLazyQuery<
    GetVolunteerSuggestionsQuery,
    GetVolunteerSuggestionsQueryVariables
  >(GET_VOLUNTEER_SUGGESTIONS, {
    variables: { departmentId: selectedDepartment.id },
    fetchPolicy: 'cache-first',
  })

  const [
    upsertVolunteer,
    { loading: upsertLoading, data: updateData, error: updateError },
  ] = useMutation<UpsertVolunteerMutation, UpsertVolunteerMutationVariables>(
    UPSERT_VOLUNTEER_MUTATION
  )

  const changeContactValues = (contact: Contact) => {
    if (!contact.name) return

    setValue('id', contact.id)
    setValue('name', contact.name)
    setValue('phone', formatPhoneNumber(contact.phone))
    setValue('birth_date', formatBirthDate(contact.birth_date))
    setValue(
      'categories',
      contact.departments_contacts_aggregate.nodes.map(
        (d) => d.department_category_id
      )
    )
  }

  const onSubmit = async (data: any) => {
    const buildCategory = (categoryId: string | null) => ({
      contact_id: data.id,
      department_id: selectedDepartment.id,
      department_category_id: categoryId,
    })

    const categories: Array<Departments_Contacts_Insert_Input> =
      data.categories.length > 0
        ? data.categories.map(buildCategory)
        : [buildCategory(null)]

    const variables: UpsertVolunteerMutationVariables = {
      id: data.id,
      birthDate:
        (data.birth_date &&
          new Date(data.birth_date).toISOString().split('T')[0]) ||
        null,
      phone: deformatPhone(data.phone),
      departmentId: selectedDepartment.id,
      name: data.name,
      categories,
    }

    await upsertVolunteer({ variables })
  }

  useEffect(() => {
    if (isCreating) {
      getVolunteersQuery()
    }
  }, [isCreating, getVolunteersQuery])

  return (
    <Dialog
      open={open}
      onClose={handleCloseEditDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {selectedDepartment.name}
      </DialogTitle>
      <DialogContent>
        <form className="mt-2" key={`contact-${contact.id}`}>
          <Box
            id="alert-dialog-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '20px',
              justifyContent: 'center',
              paddingY: '10px',
            }}
          >
            <Autocomplete
              freeSolo
              fullWidth
              defaultValue={
                data?.contacts.find((c) => c.id === contact.id) || contact
              }
              options={data?.contacts || []}
              getOptionLabel={(contact: any) => contact!.name}
              groupBy={(contact: any) => contact!.name.toUpperCase().charAt(0)}
              onChange={(_ev, contact) => {
                changeContactValues(contact as Contact)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nome"
                  variant="outlined"
                  {...register(`name`, {
                    required: true,
                  })}
                />
              )}
            />

            <Controller
              control={control}
              rules={{
                validate: {
                  validPhone: (value) =>
                    matchIsValidTel(value) || 'Celular inválido',
                },
              }}
              render={({ field, fieldState }) => (
                <MuiTelInput
                  {...field}
                  defaultCountry="BR"
                  helperText={fieldState.invalid && 'Celular inválido'}
                  error={fieldState.invalid}
                  onlyCountries={['BR']}
                  label="Celular"
                  variant="outlined"
                  className="w-100"
                />
              )}
              name="phone"
            />

            <Controller
              control={control}
              name="birth_date"
              rules={{ required: false }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Data de Nascimento"
                    onChange={(date) => field.onChange(date)}
                    inputFormat="dd/MM/yyyy"
                    className="w-100"
                    value={field.value}
                    renderInput={(input) => (
                      <TextField
                        {...input}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...register('birth_date', {
                          required: false,
                        })}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />

            <Controller
              control={control}
              name="categories"
              render={({ field: { onChange } }) => (
                <Select
                  value={selectedDepartment.department_categories.filter((dc) =>
                    getValues('categories').find((id) => id === dc.id)
                  )}
                  onChange={(val) => onChange(val.map((c) => c.id))}
                  options={selectedDepartment.department_categories}
                  getOptionLabel={(opt: Category) => opt.name}
                  getOptionValue={(opt: Category) => opt.id}
                  className="mt-2 w-100"
                  menuPosition="fixed"
                  placeholder="Selecione as funções"
                  isSearchable
                  isMulti
                />
              )}
            />
          </Box>
        </form>

        {(loading || upsertLoading) && <AbsoluteCircularProgress />}

        {updateData && (
          <AlertWithTimeout
            severity="success"
            timeout={3000}
            sx={{ width: '100%' }}
          >
            Ministério salvo com sucesso!
          </AlertWithTimeout>
        )}

        {updateError && (
          <AlertWithTimeout
            severity="error"
            timeout={3000}
            sx={{ width: '100%' }}
          >
            {updateError.message.indexOf('phone_unique_idx') > -1
              ? 'Esse número de telefone já está cadastrado em outro contato'
              : 'Não foi possível salvar as informações!'}
          </AlertWithTimeout>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="contained"
          onClick={handleCloseEditDialog}
        >
          Cancelar
        </Button>

        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || upsertLoading}
          autoFocus
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
